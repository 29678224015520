.empty-state-wrapper {

}

.empty-state-404 {
  text-align: center;
  margin-top: 250px;
  color: #E09672;
  font-size: 150px;
}

.empty-state-body {
  margin-top: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: 300;
  color: #555
}

.empty-state-description {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}