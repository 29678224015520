.container {
  position: relative;
}

.lang-box-ctr {
  width: '96%'; /* or whatever width you want. */
  max-width: 600px; /* or whatever width you want. */
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.lang-box {
  background: #4B96DD;
  border-radius: 5px;
  border-width: 0px;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px;
}

.lang-box p {
  font-size: 16px;
  font-weight: 600;
  color: white
}

.lang-box-flag {
  margin-right: 7px;
}

.lang-box-icon {
  margin-bottom: 2px;
  margin-left: 4px;
}